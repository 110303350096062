import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import classes from './Legal.module.css';
import TsnbLine from '../../components/UI/TsnbLine/TsnbLine';

function Legal(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <>
      <TsnbLine title={props.name} />
      <div className={classes.legal}>
        <div className={classes.inLegal}>
          <h1>Санкт-Петербургское госудасртвенное бюджетное учреждение<br/>"Центр ценообразования, нормирования и мониторинга закупок"</h1>
          <h2>является правообладателем сметно-нормативных баз</h2>

          <div className={classes.legalBlock}>
            <h4>Территориальная сметно-норматиная база «ГОСЭТАЛОН 2012»</h4>
            <p>Дата регистрации: <b>26.01.2015</b></p>
            <details className={classes.detail}>
              <summary>Свидетельство о государственной регистрации базы данных «Территориальная сметно-нормативная база «ГОСЭТАЛОН 2012» №2015620130 от 26.01.2015 выдано Федеральной службой по интеллектуальной собственности</summary>
              <div className={classes.certificate}>
                <Document
                  file="GE2012.pdf"
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page pageNumber={pageNumber} height="500" />
                </Document>
              </div>
            </details>
          </div>

          <div className={classes.legalBlock, classes.yellowBlock}>
            <h4>Индексы пересчета сметной стоимости строительства, реконструкции, капитального ремонта и обслуживания объектов городского хозяйства к территориальной сметно-нормативной базе «ГОСЭТАЛОН 2012»</h4>
            <p>Дата регистрации: <b>22.05.2015</b></p>
            <details className={classes.detail}>
              <summary>Свидетельство о государственной регистрации базы данных «Индексы пересчета сметной стоимости строительства, реконструкции, капитального ремонта и обслуживания объектов городского хозяйства к территориальной сметно-нормативной базе «ГОСЭТАЛОН 2012» №2015620796 от 22.05.2015 выдано Федеральной службой по интеллектуальной собственности</summary>
              <div className={classes.certificate}>
                <Document
                  file="Index.pdf"
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page pageNumber={pageNumber} height="500" />
                </Document>
              </div>
            </details>
          </div>

          <div className={classes.legalBlock}>
            <h4>Территориальный сборник сметных цен на материалы, изделия и конструкции, применяемые в строительстве. Санкт-Петербург. ТССЦ-2001</h4>
            <p>Дата регистрации: <b>23.07.2015</b></p>
            <details className={classes.detail}>
              <summary>Cвидетельство о государственной регистрации базы данных «Территориальный сборник сметных цен на материалы, изделия и конструкции, применяемые в строительстве. Санкт-Петербург. ТССЦ-2001» №2015621131 от 23.07.2015 выдано Федеральной службой по интеллектуальной собственности</summary>
              <div className={classes.certificate}>
                <Document
                  file="TSSC.pdf"
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page pageNumber={pageNumber} height="500" />
                </Document>
              </div>
            </details>
          </div>
        </div>
      </div>
    </>
  );
}

export default Legal