// Auth module
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_LOGIN = 'AUTH_LOGIN'

//TSNB module

export const CATALOG_CHANGE = 'CATALOG_CHANGE'
export const PAGE_CHANGE = 'PAGE_CHANGE'
//TSSC module


//INDEX module
