import React from 'react'
import TsnbLine from '../../components/UI/TsnbLine/TsnbLine'
import classes from './Contact.module.css'
import { Card } from 'antd';

class Contact extends React.Component {
    render () {
        return (
          <React.Fragment>
            <TsnbLine title='Контакты' />
            <div className={classes.Contacts}>
              <div className={classes.contactwrapper}>
                <div className={classes.Contact}>
                  <Card
                    title="Контакты:"
                    extra={<a href="http://cmec.spb.ru/kontakti">На сайт СПб ГБУ "ЦЦН"</a>}
                    style={{ width: '100%', boxShadow: '5px 5px 5px black' }}>
                    <p><strong>Санкт-Петербургской государственное бюджетное учреждение</strong></p>
                    <p><strong>"Центр ценообразования, нормирования и мониторинга закупок"</strong></p>
                    <p><strong>Телефон:</strong> +7 (812) 448-35-15</p>
                    <p><strong>E-mail:</strong> info@cmec.spb.ru</p>
                  </Card>
                  <Card title="Адрес:"
                    style={{ width: '100%', boxShadow: '5px 5px 5px black', marginTop: '1em'}}>
                    <p><strong>Юридический адрес:</strong></p>
                    <p>197110, г.Санкт-Петербург, ул.Барочная, д.10, корп.1, литера A</p>
                    <p><strong>Фактический адрес:</strong></p>
                    <p>197110, г.Санкт-Петербург, ул.Барочная, д.10, корп.1, литера A</p>
                  </Card>
                </div>
                <div className={classes.map}>
                  <iframe
                    title="Расположение"
                    src="https://yandex.ru/map-widget/v1/?um=constructor%3Ab575832f5c12d23f74f1ea9d825794681edb0cff6eabcccbdfb481a24535b1e7&amp;source=constructor"
                    >
                  </iframe>
                  </div>
              </div>
            </div>
          </React.Fragment>
        )
    }
}

export default Contact