import { CATALOG_CHANGE, PAGE_CHANGE } from '../actions/actionTypes'

const initialState = {
    catalog : '0000000000',
    page: 1,
    loading: false,
}

export default function tsnbReducer (state=initialState, action) {
    switch (action.type) {
        case CATALOG_CHANGE:
            return {
                ...state,
                catalog: action.catalog,
            }

        case PAGE_CHANGE:
            return {
                ...state,
                page: action.page,
            }

        default:
            return state
    }
}  