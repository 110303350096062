import {combineReducers} from 'redux'
import tsnbReducer from './tsnb'
import authReducer from './auth'
import tsscReducer from './tssc'
import indexReducer from './indexes'

export default combineReducers ({
  tsnb: tsnbReducer,
  auth: authReducer,
  tssc: tsscReducer,
  index: indexReducer,
})