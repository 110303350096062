import React, { Component } from 'react'
import classes from './Catalog.module.css'
import { Collapse } from 'antd'
import { DatePicker, Space } from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
import moment from 'moment'
import 'moment/locale/ru'
import Demo from '../UI/Tree/Tree'

const { Panel } = Collapse;

const monthFormat = 'YYYY/MM';

function disabledDate(current) {
  // Can not select month before 2020-01 and later 2021-11
  return current < moment('2020/01', monthFormat) || current > moment('2024/03', monthFormat)
}


class Catalog extends Component {
  constructor(...props) {
    super(...props)
    this.state = {
    }
  }
  handleDatePickerChange = (date, dateString) => {
    console.log(dateString)
  }

  componentDidMount(props){
  }

  renderCatalog(items) {
    return items.map((item, index) => {
      return (
            <Panel header={item.title} key={item.title}>
              <Demo code={item.code} key={index} onSelect={this.props.changeCatalog}/>
            </Panel>
      )
    })
  }

  render () {
      return (
      <div className={classes.Catalog}>
        <div className={classes.Head}>
           <h2>КАТАЛОГИ</h2>
        </div>
        <div className={classes.Body}>
          { this.props.date === '2000-01-01'
          ? null
          :<Space>
              <DatePicker
                key={this.props.date }
                style={{width:'100%'}}
                defaultValue={moment(this.props.date, monthFormat)}
                // defaultValue={moment('2020/01', monthFormat)}
                format={'YYYY-MM'}
                locale={locale}
                picker="month"
                allowClear={false}
                disabledDate={disabledDate}
                onChange={this.props.changeDate}
              />
            </Space>
          }
          <Collapse
            accordion
          >
            {this.renderCatalog(this.props.items)}
          </Collapse>
        </div>
      </div>
    )
  }
}

export default Catalog
