import React, { Component } from 'react'
// import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout } from '../../store/actions/auth'
import classes from './Logout.module.css'

class Logout extends Component {
  componentDidMount() {
    this.props.logout()
  }
  
  render() {
    
    // <Redirect to={'/'} />
    return (
      <div className={classes.Logout}>
        <div className={classes.Head}>
          <h2>Выход из системы</h2>
        </div>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(logout())
  }
}


export default connect(null, mapDispatchToProps)(Logout)