import React, { Component } from 'react';
import classes from './Tsnb.module.css';
import Catalog from '../../components/Catalog/Catalog'
import Smeta from '../../components/Smeta/Smeta'
import TsnbLine from '../../components/UI/TsnbLine/TsnbLine'
import { connect } from 'react-redux'


class Tsnb extends Component {
  
  constructor (props) {
    super(props)
    this.state = {
      catalog: {
        isLeaf: false,
        currentCode: null,
        date: this.props.date,
      },
    }
    this.onChangeDate.bind(this);
  }
  
  onChangeDate = (date, dateStr) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        catalog: {
          currentId: prevState.catalog.currentId,
          isLeaf: prevState.catalog.isLeaf,
          currentCode: prevState.catalog.currentCode,
          date: dateStr + '-01',
        }}  
    });
  }

  onChangeCatalog = (selectedKeys, info) => {
    this.setState((prevState) => {
      return {    
        ...prevState,
        catalog:
          { date: prevState.catalog.date,
            currentCode: info.node.dataRef.code,
            currentId: info.node.dataRef.id,
            isLeaf: info.node.dataRef.isLeaf,
      }}
    })  
  }

  render(){
    return (
      <React.Fragment>
        <TsnbLine title={this.props.name} />
        <div className={classes.Tsnb}> 
          <div className={classes.Menu}>
            <Catalog 
              items={this.props.items} 
              date={this.state.catalog.date} 
              changeDate={this.onChangeDate}
              changeCatalog={this.onChangeCatalog}
            />
          </div>
          <div className={classes.Smeta}>
            <Smeta
              style={{'fontSize': '0.6em'}}
              module={this.props.module}
              date={this.state.catalog.date}
              key={this.state.catalog.currentId} 
              catalog={this.state.catalog.currentCode}
              catalogId={this.state.catalog.currentId} 
              isLeaf={this.state.catalog.isLeaf}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}
 
function mapStateToProps(state) {
  return {
    catalog: state.tsnb.catalog,
    loaging: state.tsnb.loading,
    page: state.tsnb.page,
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Tsnb)