import React, { Component } from 'react';
import classes from './TsnbLine.module.css'

class TsnbLine extends Component {

  render (props) {
    return (
      <div className={classes.TsnbLine}> 
        <h4> {this.props.title}</h4>
      </div>
      
    )
  }
}

export default TsnbLine