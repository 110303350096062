import React, { Component } from 'react';
import classes from './Footer.module.css';


class Footer extends Component {

  render () {
    return (
      <div className={classes.Footer}>
        <a href="http://cmec.spb.ru">All rights reserved &#169; СПб ГБУ "ЦЦН" </a>
      </div>
    )
  }
}

export default Footer