import React from 'react'
import { Tree, Spin } from 'antd';
import axios from 'axios'

const TreeNode = Tree.TreeNode

async function getChild(code, key) {
  try {
    const url = `https://tsnb.spb.ru/api/v1/tsnb/catalog/${code}/`
    const token = localStorage.getItem('token')
    const instance = axios.create({
      baseURL: '',  
      headers: {'Authorization': `Token ${token}`}
    });
    const response = await instance.get(url)
    const data = response.data

    const items =  data.map((node, index) => {
      return ({
        id: node.id,
        title: node.title,
        key: `${key}-${index}`,
        code: node.code,
        isLeaf: node.isLeaf,
      })
    })
    
    return items
  } catch (e) {
  console.log(e)
  }
}

export default class Demo extends React.Component {
  
  state = {
    loading: false,
    code: null,
    treeData: [
    ],
  }

  async componentDidMount () {
    try {
      const url = `https://tsnb.spb.ru/api/v1/tsnb/catalog/${this.props.code}/`
      const token = localStorage.getItem('token')
      const instance = axios.create({
        baseURL: '',  
        headers: {'Authorization': `Token ${token}`}
      });
      let response = await instance.get(url)
      response = response.data
  
      const items =  response.map((node, index) => {
        return ({
          id: node.id,
          title: node.title,
          key: index,
          code: node.code,
          isLeaf: node.isLeaf,
        })
      })
      this.setState(
        {
          code: this.props.code,
          treeData : items,
          loading: true,
        }
      )

    } catch (e) {
    console.log(e)
    }
  }

  onLoadData = (treeNode) => {
    return new Promise((resolve) => {
      if (treeNode.props.children) {
        resolve();
        return;
      }
      setTimeout(() => {
        getChild(treeNode.props.dataRef.code, treeNode.props.dataRef.key).then((data) => {
          treeNode.props.dataRef.children = data
          this.setState({
            treeData: [...this.state.treeData],
          });
        })
        resolve(this.state.treeData);
      }, 500);
    });
  }

  renderTreeNodes = (data) => {
    return data.map((item) => {
      if (item.children) {
        return (
          <TreeNode title={item.title} key={item.key} dataRef={item}>
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode {...item} dataRef={item} />;
    });
  }

  render() {
    const { DirectoryTree } = Tree;

    return (
      <React.Fragment>
      { !this.state.loading 
        ? <Spin/>
        :
          <DirectoryTree 
            // switcherIcon
            showLine
            showIcon={false}
            showLeafIcon = {null}
            loadData={this.onLoadData} 
            onSelect={this.props.onSelect}>
            {this.renderTreeNodes(this.state.treeData)}
          </DirectoryTree>
      }
      </React.Fragment>
    )
  }
}
