import React, { Component } from 'react';
import classes from './Index.module.css';
import TsnbLine from '../../components/UI/TsnbLine/TsnbLine'

class Index extends Component {

  render() {
    return (
      <div className={classes.Index}>
        <TsnbLine title="ТСНБ &laquo;ГОСЭТАЛОН&nbsp;2012&raquo;" />
        <div className={classes.Intro}>
          <h3>Базы данных &laquo;Территориальная сметно-нормативная база &laquo;ГОСЭТАЛОН&nbsp;2012&raquo;, &laquo;Территориальный сборник сметных цен на материалы, изделия и конструкции, применяемые в строительстве. Санкт-Петербург. ТССЦ-2001&raquo;, &laquo;Индексы пересчета сметной стоимости строительства, реконструкции, капитального ремонта и обслуживания объектов городского хозяйства к Территориальной сметно-нормативной базе &laquo;ГОСЭТАЛОН&nbsp;2012&raquo; созданы и актуализируются в целях организации деятельности исполнительных органов государственной власти Санкт-Петербурга по эффективному и экономному использованию средств бюджета Санкт-Петербурга. ТСНБ &laquo;ГОСЭТАЛОН&nbsp;2012&raquo;, ТССЦ, Индексы пересчета используются заказчиками Санкт-Петербурга и участниками инвестиционно-строительного комплекса для определения сметной стоимости работ и расчетов за выполненные работы.</h3>
          <h3>Территориальная сметно-нормативная база Санкт-Петербурга «ГОСЭТАЛОН&nbsp;2012» (база данных &laquo;Территориальная сметно-нормативная база &laquo;ГОСЭТАЛОН&nbsp;2012&raquo;, далее ТСНБ &laquo;ГОСЭТАЛОН&nbsp;2012&raquo;) – представленная в объективной форме совокупность материалов (информации) в электронном виде: расценки на работы, ресурсы (материалы, изделия и конструкции, машины и механизмы) общие указания, технические части, общие положения, правила исчисления объемов работ и иные материалы.</h3>
          <h3>Индексы пересчета сметной стоимости строительства, реконструкции, реставрации, ремонта, содержания и эксплуатации объектов в Санкт-Петербурге, осуществляемых за счет средств бюджета Санкт-Петербурга, к ТСНБ &laquo;ГОСЭТАЛОН&nbsp;2012&raquo; (база данных - «Индексы пересчета сметной стоимости строительства, реконструкции, капитального ремонта и обслуживания объектов городского хозяйства к Территориальной сметно-нормативной базе &laquo;ГОСЭТАЛОН&nbsp;2012&raquo;, далее Индексы пересчета). </h3>
          <h3>Территориальный сборник сметных цен на материалы, изделия и конструкции, применяемые в строительстве. Санкт-Петербург. ТССЦ-2001 (база данных – «Территориальный сборник сметных цен на материалы, изделия и конструкции, применяемые в строительстве. Санкт-Петербург. ТССЦ-2001», далее ТССЦ). </h3>
          <h2>ТСНБ &laquo;ГОСЭТАЛОН&nbsp;2012&raquo;, Индексы пересчета и ТССЦ разработаны специалистами СПб ГБУ &laquo;Центр ценообразования, нормирования и мониторинга закупок&raquo;.</h2>
          <h2>По вопросам приобретения прав на использование и распространение баз ТСНБ &laquo;ГОСЭТАЛОН&nbsp;2012&raquo;, Индексы пересчета и ТССЦ обращайтесь по телефону: 8 (812) 448-35-15 и e-mail: info@cmec.spb.ru.</h2>
        </div>
      </div>
    )
  }
}

export default Index