import React, { Component } from 'react';
import classes from './Drawer.module.css';
import { NavLink } from 'react-router-dom';
import Backdrop from '../../UI/Backdrop/Backdrop';
import spb from '../../../images/SPb.png'
import {
  LoginOutlined,
  LogoutOutlined,
  HomeOutlined,
  LayoutOutlined,
  ReadOutlined,
  TeamOutlined,
  ScheduleOutlined,
  ApiOutlined,
  BookOutlined,
}
from '@ant-design/icons';


class Drawer extends Component {

  clickHandler = () => {
      this.props.onClose()
  }

  renderLinks(links) {

    return links.map((link, index) => {

      return (
        <li key={index}>
          <NavLink
            to={link.to}
            exact={link.exact}
            activeClassName={classes.active}
            onClick={this.clickHandler}
            icon={this.icon}
          >
            {link.icon} &nbsp;
            {link.label}
          </NavLink>
        </li>

      )
    })
  }


  render () {
    const cls = [classes.Drawer]

    if (!this.props.isOpen) {
        cls.push(classes.close)
    }

    const links = [
        { to: '/', label: 'На главную', exact: true, icon: <HomeOutlined />},
        { to: '/legal', label: 'Правовая информация', exact: false, icon: <BookOutlined />},
        { to: '/sale', label: 'Приобретение баз', exact: false, icon: <ScheduleOutlined />},
        { to: '/manual', label: 'Руководство пользователя', exact: false, icon: <ReadOutlined />},
        { to: '/contact', label: 'Контакты', exact: false, icon: <TeamOutlined />},
    ]

    if(this.props.isAuthenticated) {
        links.push ({ to: '/api', label: 'Описание API', exact: false, icon: <ApiOutlined /> })
        links.push ({ to: '/tsnb', label: 'ТСНБ ГОСЭТАЛОН 2012', exact: false, icon: <LayoutOutlined /> })
        links.push ({ to: '/tssc', label: 'ТССЦ', exact: false, icon: <LayoutOutlined />, data: '2020.01'})
        links.push ({ to: '/indexes', label: 'Индексы пересчета ', exact: false, icon: <LayoutOutlined />, data: '2020.01'})
        links.push ({ to: '/tsnbtsne', label: 'ТСНБ ГОСЭТАЛОН 2012: Эксплуатация', exact: false, icon: <LayoutOutlined /> })
        links.push ({ to: '/tsnetssc', label: 'ТССЦ к ТСНБ Эксплуатация', exact: false, icon: <LayoutOutlined /> })
        links.push ({ to: '/tsneindex', label: 'Индексы к ТСНБ Эксплуатация', exact: false, icon: <LayoutOutlined /> })
        links.push ({ to: '/logout', label: 'Выход из системы', exact: false, icon: <LogoutOutlined />})
    } else {
        links.push ({ to: '/auth', label: 'Авторизация', exact: false, icon: <LoginOutlined />})
    }
    return (
      <React.Fragment>
        <nav className={cls.join(' ')}>
          <ul>
            { this.renderLinks(links) }
          </ul>
          <img src={spb} alt="spb" />
        </nav>

        {this.props.isOpen ? <Backdrop onClick={this.props.onClose}/> : null}
      </React.Fragment>
    )
  }
}


export default Drawer