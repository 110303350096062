import React from 'react'
import TsnbLine from '../../components/UI/TsnbLine/TsnbLine'
import classes from './Api.module.css'
import { Collapse, Typography, Divider } from 'antd';


const { Panel } = Collapse;
const { Text, Link } = Typography;

class Api extends React.Component {
    render () {
        return (
          <React.Fragment>
            <TsnbLine title='Описание API' />
            <div className={classes.Api}>
              <div className={classes.apiwrapper}>
                <Collapse 
                  className="site-collapse-custom-collapse"
                  >
                  <Panel header="Вводные данные" key="1">
                    <Text strong>Основной URL для работы с API баз данных: <Link> https://tsnb.spb.ru/api/v1/</Link></Text>
                    <Divider />
                    <p>Доступ к информации находящейся в базах предостовляется только авторизованных пользователей. (см. пункт "Авторизация")</p>
                    <p>Данные в базах организованы в виде дерева каталогов. Для получения списка дочерних (вложенных) каталогов нужно отправить запрос на сервер. (см. пункт "Работа с каталогами")</p>

                    <Collapse>
                      <Panel header="Коды заглавных сборников каталога" key="11">
                        <p><Text code>1000000000</Text> - Каталог ТССЦ</p>
                        <p><Text code>2000000000</Text> - Каталог ТЕР</p>
                        <p><Text code>3000000000</Text> - Каталог ТСНэ-ТЕРэ</p>
                        <p><Text code>4000000000</Text> - Каталог укрупненых индексов к ТСНБ</p>
                      </Panel>
                    </Collapse>
                  </Panel>
                  <Panel header="Авторизация" key="2">
                    <p>Для работы с API сначала необходимо получить токен авторизации <Text type="success">token</Text>. Для получения токена необходимо указать учетные данные предосставленные сотрудниками СПб ГБУ "Центр мониторинга и экспертизы цен". Функционал для сменя пароля запланирован к разработке.</p>
                    <Text strong>Для получения <Text type="success">token</Text> необходимо отправить POST-запрос на URL: <Link>auth/token/login/</Link>.</Text> 
                    <Text> В теле запроса необходимо указать учетные данные.</Text>
                    <p></p>
                    <Collapse>
                      <Panel header="Пример запроса для получения токена" key="21">
                        <Text code>curl --request POST https://tsnb.spb.ru/api/v1/auth/token/login/ -d 'email=xyz@xyz.ru&password=password'</Text>
                      </Panel>
                      <Panel header="Пример ответа на запрос авторизации" key="22">
                        <p>При корректно отправленном запросе получите следующий ответ:</p>
                        <Text code>&#123;"auth_token":"1234567890abcdef1234567890abcdef12345678"&#125;</Text>
                        <Divider />
                        <p>При отправке некорректных данных поступит следующий ответ:</p>
                        <Text code>&#123;"non_field_errors":["Невозможно войти с предоставленными учетными данными."]&#125;</Text>
                      </Panel>
                    </Collapse>
                  </Panel>
                  <Panel header="Работа с деревом каталогов" key="3">
                    <p>Работа с каталогами необходимо получить токен авторизации <Text type="success">token</Text> (см. пункт "Акторизация").</p>
                    <p>Если <Text type="success">token</Text> уже получен, то есть возможность получить список дочерних каталогов для определенного каталога зная его 10-значный код каталога &#123;catalog_code&#125;.</p>
                    <Text strong>Для получения необходимо отправить GET-запрос на URL: <Link>tsnb/catalog/&#123;catalog&#125;/</Link>.</Text>
                    <Collapse>
                      <Panel header="Пример запроса для получения списка дочерних каталогов" key="21">
                        <Text code>curl --request GET -H "Authorization: Token &#123;token&#125;" https://tsnb.spb.ru/api/v1/tsnb/catalog/1100000000/</Text>
                      </Panel>
                      <Panel header="Пример ответа на запрос списка дочерних каталогов" key="22">
                        <p>При корректно отправленном запросе получите ответ следующего содержания:</p>
                        <Text code>
                          [
                            &#123;"id":5,"code":"1100000000","title":"Территориальные сметные цены на материалы, изделия и конструкции, применяемые в строительстве ТССЦ 81-01-2001","isLeaf":false&#125;,
                            &#123;"id":6,"code":"1200000000","title":"Территориальные сметные расценки на эксплуатацию строительных машин и автотранспортных средств ТСЭМ 81-01-2001","isLeaf":false&#125;,
                            &#123;"id":7,"code":"1300000000","title":"Территориальные сметные цены на перевозку грузов для строительства. ТССЦпг 81-01-2001","isLeaf":false&#125;
                          ]
                        </Text>
                        <p>где</p>
                        <p><Text code>id</Text> - идентификационный номер дочернего каталога</p>
                        <p><Text code>code</Text> - код дочернего каталога</p>
                        <p><Text code>title</Text> - наименование дочернего каталога </p>
                        <p><Text code>isLeaf</Text> - свойство дочернего каталога, которое указывает на то, является ли он родительским каталогом или это конечный каталог. </p>
                        <p>Если значение <Text italic>false</Text> - дочерний каталог содержит другие дочерние каталоги.</p>
                        <p>Если значение <Text italic>true</Text> - дочерний каталог не содержит другие дочерние каталоги и в нем находятся данные (см. пункт "Получение данных позиций внутри каталога").</p>
                        <Divider />
                        <p>При отправке некорректного <Text type="success">token</Text> поступит следующий ответ:</p>
                        <Text code>&#123;"detail":"Учетные данные не были предоставлены."&#125;</Text>
                      </Panel>
                    </Collapse>
                  </Panel>
                  <Panel header="Получение данных позиций внутри каталога" key="4">
                    <p>Для работы с данными внутри каталогов используются следующие конечные точки (endpoint)</p>
                    <p><Link>tsnb/&#123;id&#125;/tssc/</Link> - Получение позиций каталога с идентификационным номер &#123;id&#125;сборника ТССЦ в ТСНБ"ГОСЭТАЛОН2012"</p>
                    <p><Link>indexes/&#123;id&#125;/ter/&#123;date&#125;</Link> - Получение позиций каталога с идентификационным номер &#123;id&#125; сборника ТССЦ на указанную дату.</p>
                    <p><Link>indexes/&#123;id&#125;/tsne/&#123;date&#125;</Link> - Получение позиций каталога с идентификационным номер &#123;id&#125; сборника ТССЦ на указанную дату.</p>
                    <p>Формат даты &#123;date&#125;:?date=YYYY-MM-01, где YYYY - номер года в четырехзначном формате, MM - номер месяца в двухзначном формате.</p>
                    <Collapse>
                      <Panel header="Пример запроса для получения позиций каталогов" key="21">
                        <Text code>curl --request GET -H "Authorization: Token &#123;token&#125;" https://tsnb.spb.ru/api/v1/tsnb/603/tssc/?date=2021-02-01;</Text>
                      </Panel>
                      <Panel header="Пример ответа на запрос позиций каталогов" key="22">
                        <p>При корректно отправленном запросе получите ответ следующего содержания:</p>
                        <Text code>
                          [<br/>
                            &#123;"id":16963,"code":"101-9739П", "title":"Праймер битумный", "cargo_class":"No", "measure":"кг", "prices":[&#123;"type":"Сметная цена, руб.","price":"8.00"&#125;]&#125;, <br/>
                            &#123;"id":16961,"code":"113-2219","title":"Битумно-смоляной отвердитель (праймер)","cargo_class":"No","measure":"т","prices":[&#123;"type":"Сметная цена, руб.","price":"11072.50"&#125;]&#125;,<br/>
                            ]
                        </Text>
                        <p>где</p>
                        <p><Text code>id</Text> - идентификационный номер ресурса</p>
                        <p><Text code>code</Text> - код ресурса в справочнике</p>
                        <p><Text code>title</Text> - наименование ресурса</p>
                        <p><Text code>cergo_class</Text> - класс груза, возможные значения: </p>
                        <p><Text code>isLeaf</Text> - свойство дочернего каталога, которое указывает на то, является ли он родительским каталогом или это конечный каталог. </p>
                        <Divider />
                        <p>При отправке некорректного <Text type="success">token</Text> поступит следующий ответ:</p>
                        <Text code>&#123;"detail":"Учетные данные не были предоставлены."&#125;</Text>
                      </Panel>
                    </Collapse>
                  </Panel>
                </Collapse>
              </div>
            </div>
          </React.Fragment>
        )
    }
}

export default Api