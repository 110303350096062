import axios from 'axios'
import { AUTH_SUCCESS, AUTH_LOGOUT } from './actionTypes'

export function auth(email, password, isLogin) {
  return async dispatch => {

    const authData = {
      email, password,
    }

    let url = 'https://tsnb.spb.ru/api/v1/auth/token/login/'
    
    const response = await axios.post(url, authData)
    const data = response.data

    localStorage.setItem('token', data.auth_token)
    localStorage.setItem('userEmail', email)

    dispatch (authSuccess(data.auth_token))
  }
}

export function logout() {
  return async dispatch => {
    const token = localStorage.getItem('token')

    let url = 'https://tsnb.spb.ru/api/v1/auth/token/logout/'
    const instance = axios.create({
      baseURL: '',
      headers: {'Authorization': `Token ${token}`}
    });
    instance.post(url)
  
    localStorage.removeItem('token')
    localStorage.removeItem('userEmail')
    
    dispatch(logoutSuccess())
  }
}

export function autoLogin(){
  return dispatch => {
    const token = localStorage.getItem('token')
    if(!token) {
      dispatch(logoutSuccess())
    } else {
      dispatch(authSuccess(token))
    }
  }
}

export function authSuccess (token) {
  return {
    type: AUTH_SUCCESS,
    token
  }
}

export function logoutSuccess() {
  return {
    type: AUTH_LOGOUT,
  }
}