import React from 'react';
import classes from './MenuToggle.module.css';
import { MenuUnfoldOutlined, MenuFoldOutlined }  from '@ant-design/icons'

const MenuToggle = props => {
    const cls =[
        classes.MenuToggle,
    ]

    if (props.isOpen) {
        cls.push(classes.open)
    } 

    return (
        <div className={cls.join(' ')} onClick={props.onToggle}>
            { props.isOpen ? <MenuFoldOutlined /> : <MenuUnfoldOutlined/> }
        </div>
    )
}

export default MenuToggle