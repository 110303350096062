import React, { Component } from 'react';
import classes from './Smeta.module.css';
import { Table, Alert, Spin, Pagination, Input } from 'antd';
import axios from 'axios'
// import Highlighter from 'react-highlight-words';
// import { SearchOutlined } from '@ant-design/icons';
// import { get, isequal} from 'lodash'


const { Search } = Input;

const translate = {
  code : 'Код ресурса',
  title: 'Наименование',
  measure: 'Единица измерения',
  'Номер': 'code',
  'Код ресурса': 'code',
  'Расстояние перевозки': 'title',
  'Наименование' : 'title',
  'Единица измерения': 'measure',
  'Сметная цена, руб.': 'price_smeta',
  'Класс груза': 'cargo_class',
  'Цена перевозки': 'price_smeta',
  'Цена перевозки, руб': 'price_smeta',
  'Тарифная ставка': 'price_smeta',
  'Сметная стоимость маш-ч': 'price_smeta',
  'Прямые затраты, руб.' : 'price_smeta',
  'Оплата труда рабочих, руб.': 'price_labour',
  'Оплата труда': 'price_labour',
  'Материалы': 'price4',
  'Затраты на эксплуатацию машин, руб.': 'price_exp',
  'в т.ч. оплата труда машинистов, руб.': 'price_exp_lab',
  'В т.ч. оплата труда машинистов': 'price_exp_lab',
  'Расход неучтенных материалов, руб.': 'price_mat',
  'На весь комплекс работ': 'price1',
  'Оплата труда рабочих': 'price2',
  'Эксплуатация машин и механизмов': 'price3',
  'Сметная стоимость материалов': 'price4',
  'Сметная стоимость оборудования': 'price5',
  'Оплата труда механиков': 'price6',
}

function getURLByCode(id , code, module) {
  let url = ''
  
  if (code[0] === '1') {
    url = `https://tsnb.spb.ru/api/v1/${module}/${id}/tssc/`
    return url
  }
  if (code[0] === '2') {
    url = `https://tsnb.spb.ru/api/v1/${module}/${id}/ter`
    if (module === 'indexes') {
      url = url +'i/'
    } else {
      url = url +'/'
    }
    return url
  }
  if (code[0] === '3') {
    url = `https://tsnb.spb.ru/api/v1/${module}/${id}/tsne`
    if (module === 'indexes') {
      url = url +'i/'
    } else {
      url = url +'/'
    }
    return url
  }
  if (code[0] === '4') {
    url = `https://tsnb.spb.ru/api/v1/${module}/${id}/indexge/`
    return url
  }
  if (code[0] === '5') {
    url = `https://tsnb.spb.ru/api/v1/${module}/${id}/indexge/`
    return url
  }
  if (code[0] === '6') {
    url = `https://tsnb.spb.ru/api/v1/${module}/${id}/indexge/`
    return url
  }
  if (code[0] === '7') {
    url = `https://tsnb.spb.ru/api/v1/${module}/${id}/indexge/`
    return url
  }
}

class Smeta extends Component {
  constructor (props) {
    super(props);
    this.state = {
      bordered: true,
      loading: false,
      scroll: undefined,
      hasData: false,
      showHeader: true,
      
      current: 1,
      total: 1,
      tableLayout: 'auto',
      width: 100,
  
      catalog: {},
      endPoint: false,
      data: [],
        
      isFiltered: false,
      searchText: '',
      searchedColumn: '',
    }
  }
  
  async componentDidMount () {
    try {
      const token = localStorage.getItem('token')
      let cols = []
      let tableData = []
      const catalog = {}
      const isLeaf = this.props.isLeaf
      if (isLeaf) {
        const url2 = `https://tsnb.spb.ru/api/v1/tsnb/catalog/detail/${this.props.catalog}/`
        const instance2 = axios.create({
          baseURL: '',  
          headers: {'Authorization': `Token ${token}`}
        });
        const response2 = await instance2.get(url2)
        for (let key in response2.data){
          catalog[key]= response2.data[key]
        }

        const url3 = getURLByCode(this.props.catalogId, this.props.catalog, this.props.module)
        const instance3 = axios.create({
          baseURL: '',  
          headers: {'Authorization': `Token ${token}`},
          params: {date: this.props.date},
        });
        
        cols = catalog.table_type.fields_array.map((name, index) => {
          return {
            title: name,
            dataIndex: translate[name],
            key: index,
          }
        })

        const response3 = await instance3.get(url3)
        
        tableData = response3.data.map((item, index) => {

          let handleItem = {key: `${index}`}
          
          if ((this.props.module === 'indexes') && (index === 0) && (this.props.catalog.startsWith('2') || this.props.catalog.startsWith('3'))) {
            cols = cols.slice(0, 3)
            cols.forEach((col) => {
              if (Object.keys(item).indexOf(col.dataIndex) !== -1) {
                handleItem[col.dataIndex] = item[col.dataIndex]
              }   
            })
            item.prices.forEach((price, i) => {
              cols.push({title: price.type, dataIndex: translate[price.type], key: 3+i})
              handleItem[translate[price.type]] = price.price
            })
          } else {
            cols.forEach((col) => {
              if (Object.keys(item).indexOf(col.dataIndex) !== -1) {
                handleItem[col.dataIndex] = item[col.dataIndex]
              } else if ( col.dataIndex.startsWith('price') ) {
                handleItem[col.dataIndex] = item.prices.find(vall => vall.type === col.title).price
              }
            })
          }

          return handleItem
        })
      } 

      this.setState({
        catalog: catalog,
        endPoint: isLeaf,
        hasData: true,
        cols: cols,
        data: tableData,
      }) 
      
    } catch (e) {
    console.log(e)
    }
  }

  async componentDidUpdate(prevProps) {
    
    if (this.props.date !== prevProps.date) {
      try {
        const token = localStorage.getItem('token')
        let cols = []
        let tableData = []
        const catalog = {}
        const isLeaf = this.props.isLeaf
        if (isLeaf) {
          const url2 = `https://tsnb.spb.ru/api/v1/tsnb/catalog/detail/${this.props.catalog}/`
          const instance2 = axios.create({
            baseURL: '',  
            headers: {'Authorization': `Token ${token}`}
          });
          const response2 = await instance2.get(url2)
          for (let key in response2.data){
            catalog[key]= response2.data[key]
          }
  
          const url3 = getURLByCode(this.props.catalogId, this.props.catalog, this.props.module)
          
          const instance3 = axios.create({
            baseURL: '',  
            headers: {'Authorization': `Token ${token}`},
            params: {date: this.props.date},
          });
          
          cols = catalog.table_type.fields_array.map((name, index) => {
            return {
              title: name,
              dataIndex: translate[name],
              key: index,
            }
          })
  
          const response3 = await instance3.get(url3)
          
          tableData = response3.data.map((item, index) => {
              
            let handleItem = {key: `${index}`}
            
            if ((this.props.module === 'indexes') && (index === 0) && (this.props.catalog.startsWith('2') || this.props.catalog.startsWith('3'))) {
              cols = cols.slice(0, 3)
              cols.forEach((col) => {
                if (Object.keys(item).indexOf(col.dataIndex) !== -1) {
                  handleItem[col.dataIndex] = item[col.dataIndex]
                }   
              })
              item.prices.forEach((price, i) => {
                cols.push({title: price.type, dataIndex: translate[price.type], key: 3+i})
                handleItem[translate[price.type]] = price.price
              })
            } else {
              cols.forEach((col) => {
                if (Object.keys(item).indexOf(col.dataIndex) !== -1) {
                  handleItem[col.dataIndex] = item[col.dataIndex]
                } else if ( col.dataIndex.startsWith('price') ) {
                  handleItem[col.dataIndex] = item.prices.find(vall => vall.type === col.title).price
                }
              })
            }
  
            return handleItem
          })
        } 
  
        this.setState({
          catalog: catalog,
          endPoint: isLeaf,
          hasData: true,
          cols: cols,
          data: tableData,
        }) 
        
      } catch (e) {
      console.log(e)
      }

    }
  }

  onSearch = value => {
    if (value.length > 2){

      const data = this.state.data
      const filteredData = data.filter(elem => {
          return elem.title.indexOf(value) + 1
        })
      
      this.setState((prevState) =>{
      return {
        ...prevState,
        isFiltered: true,
        filterValue: value,
        prevData: data,
        data: filteredData,

      }
    })
    }
    if (value === '') {
      this.setState((prevState) =>{
        return {
          ...prevState,
          isFiltered: false,
          filterValue: null,
          data: prevState.prevData,
        }
    })
    }
  }

  render () {
    
    return (
      <div className={classes.Smeta}>
        {!!this.state.endPoint
        ? <div className={classes.Search}>
            <Search placeholder="Поиск по наименованию" onSearch={this.onSearch} style={{ width: 300, height: '30px', margin: '0px' }} allowClear />
          </div>
        : null
        }
        
        
        <div className={classes.output}>
          {!this.state.endPoint
            ? 
              <React.Fragment>
                <Alert
                  message="Внимание!"
                  description="Для отображения данных необходимо выбрать конечный каталог!"
                  type="warning"
                  // closable
                  // onClose={onClose}
                />
                <div className={classes.outLoad}>
                  <Spin size="large" style={{}}/>
                </div>
              </React.Fragment>
            :
              <Table
                {...this.state}
                className={classes.Table}
                pagination= {{ position: ['bottomCenter'], }}
                dataSource={this.state.hasData ? this.state.data : null}
                columns={this.state.hasData ? this.state.cols : null}
                // size="small"
                > 
                <Pagination 
                  position='bottomCenter'
                  current={this.state.current}
                  onChange={this.onChange}
                  total={this.state.total}
                  itemRender={this.itemRender}
                />
              </Table>
          }
        </div>
      </div>
    )
  }
}


export default Smeta