import React, { Component} from 'react';
import Layout from './hoc/Layout/Layout';
import { Route, Switch, Redirect, withRouter} from 'react-router-dom';
import Auth from './containers/Auth/Auth';
import Tsnb from './containers/Tsnb/Tsnb';
import Manual from './containers/Manual/Manual';
import Index from './containers/Index/Index';
// import Registration from './containers/Registration/Registration';
import Api from "./containers/API/Api"
import {Sale} from './containers/Sale/Sale';
import Legal from './containers/LegalInfo/Legal';
import {connect} from 'react-redux';
import Logout from './components/Logout/Logout';
import {autoLogin} from './store/actions/auth';
import Contact from './containers/Contact/Contact';

class App extends Component {
  state = {
    tsnb: [
      {code: '1000000000', title: 'КАТАЛОГ ТССЦ'},
      {code: '2000000000', title: 'КАТАЛОГ ТЕР'},
      {code: '3000000000', title: 'КАТАЛОГ ТСНэ-ТЕРэ'},
    ],
    tssc : [
      {code: '1100000000', title: 'Территориальные сметные цены на материалы, изделия и конструкции, применяемые в строительстве'},
      {code: '1200000000', title: 'Территориальные сметные расценки на эксплуатацию строительных машин и автотранспортных средств.'},
      {code: '1300000000', title: 'Территориальные сметные цены на перевозку грузов для строительства.'},
      {code: '1400000000', title: 'Тарифные ставки.'},
    ],
    indexes: [
      {code: '2000000000', title: 'Индексы к Территориальным единичным расценкам'},
      {code: '4000000000', title: 'Индексы по видам работ и объектам'},
      {code: '3000000000', title: 'Индексы к ТСНэ-ТЕРэ'},
    ],
    tsne: [
      {code: '3000000000', title: 'КАТАЛОГ ТСНэ-ТЕРэ'},
      {code: '1000000000', title: 'КАТАЛОГ ТССЦ к ТСНэ-ТЕРэ' },
    ],
    tsnetssc: [
      {code: '1100000000', title: 'Территориальные сметные цены на материалы, изделия и конструкции, применяемые в строительстве'},
      {code: '1200000000', title: 'Территориальные сметные расценки на эксплуатацию строительных машин и автотранспортных средств.'},
      {code: '1300000000', title: 'Территориальные сметные цены на перевозку грузов для строительства.'},
    ],
    tsneindex: [
      {code: '3000000000', title: 'Индексы к ТСНэ-ТЕРэ к ТСНБ Эксплуатация'},
    ],
  }

  componentDidMount(){
    this.props.autoLogin()
  }

  render() {
    let routes =(
      <Switch>
        <Route path='/auth' component={Auth} />
        <Route path='/manual' component={Manual} />
        <Route path='/contact' exact component={Contact} />
        {/* <Route path='/documents' exact component={Registration} /> */}
        <Route path='/legal' exact
            render={() => <Legal name="Правовая информация" key='4' />} />
        <Route path='/sale' exact
          render={() => <Sale name="Приобретение баз" key='5' />} />
        <Route path='/' exact component={Index} />
        <Redirect to={'/'} />
      </Switch>
    )

    if (this.props.isAuthenticated) {
      routes =(
        <Switch>
          <Route
            path='/tsnb'
            render={() => <Tsnb name="ТСНБ &laquo;ГОСЭТАЛОН 2012&raquo;" items={this.state.tsnb} date='2000-01-01' module='tsnb' key='1' />}
          />
          <Route
            path='/indexes'
            render={() => <Tsnb name="Индексы пересчета сметной стоимости" items={this.state.indexes} date='2020-01-01' module='indexes' key='2'/>}
          />
          <Route
            path='/tssc'
            render={() => <Tsnb name="ТССЦ" items={this.state.tssc} date='2020-01-01' module='tssc' key='3'/>}
          />
          <Route
            path='/tsnbtsne'
            render={() => <Tsnb name="ТСНБ &laquo;ГОСЭТАЛОН 2012: Эксплуатация&raquo;" items={this.state.tsne} date='2022-01-01' module='tsnbtsne' key='4'/>}
          />
          <Route
            path='/tsnetssc'
            render={() => <Tsnb name="ТССЦ к ТСНБ &laquo;ГОСЭТАЛОН 2012: Эксплуатация&raquo;" items={this.state.tsnetssc} date='2023-02-01' module='tsnetssc' key='5'/>}
          />
          <Route
            path='/tsneindex'
            render={() => <Tsnb name="Индексы к ТСНБ &laquo;ГОСЭТАЛОН 2012: Эксплуатация&raquo;" items={this.state.tsneindex} date='2023-02-01' module='tsneindex' key='6'/>}
          />
          <Route path='/manual' component={Manual} />
          <Route path='/legal' exact
            render={() => <Legal name="Правовая информация" key='7' />} />
          <Route path='/sale' exact
            render={() => <Sale name="Приобретение баз" key='8' />} />
          <Route path='/contact' exact component={Contact} />
          {/* <Route path='/documents' exact component={Registration} /> */}
          <Route path='/logout' component={Logout} />
          <Route path='/api' exact component={Api} />
          <Route path='/' exact component={Index} />
          <Redirect to={'/'} />
        </Switch>
      )
    }

    return  (
      <Layout>
        {routes}
      </Layout>
    )
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.auth.token
  }
}

function mapDispatchToProps(dispatch) {
  return {
    autoLogin: () => dispatch(autoLogin())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
