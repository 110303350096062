import React from 'react';
import classes from './Sale.module.css';
import TsnbLine from '../../components/UI/TsnbLine/TsnbLine'
import { Divider, Table, Alert } from 'antd';


const { Column } = Table;

const data = [
  {
    key: '1',
    Num: '1',
    license: 'Простая (неисключительная) лицензия на право использования экземпляра ТСНБ «ГОСЭТАЛОН 2012»',
    priceDev: '9702.00',
    priceUser: '13860.00',
  },
  {
    key: '2',
    Num: '2',
    license: 'Простая (неисключительная) лицензия на право использования Дополнений к ТСНБ «ГОСЭТАЛОН 2012» в формате сметной программы лицензиата',
    priceDev: '2910.00',
    priceUser: '4158.00',
  },
  {
    key: '3',
    Num: '3',
    license: 'Простая (неисключительная) лицензия на право использования Индексов* в формате сметной программы лицензиата',
    priceDev: '2424.00',
    priceUser: '3456.00',
  },
];

export const Sale = (props) => {
  return (
    <React.Fragment>
      <TsnbLine title={props.name} />
      <div className={classes.sale}>
        <div className={classes.inSale}>
          <div className={classes.prices}>
            <Table dataSource={data} pagination={false} bordered={true}>
              <Column title=" " dataIndex="Num" key="Num" />
              <Column title="Наименование лицензии" dataIndex="license" key="lic" />
              <Column title="Стоимость для конечных пользователей, руб." dataIndex="priceUser" key="price" align="center"  width='15%'/>
            </Table>
            <Divider />
            <Alert
              message="Внимание"
              description="*- Индексы включают в себя базу «Индексы пересчета сметной стоимости строительства, реконструкции, реставрации, ремонта, содержания и эксплуатации объектов в Санкт-Петербурге, осуществляемых за счет средств бюджета Санкт-Петербурга, к ТСНБ «ГОСЭТАЛОН 2012» и базу «Территориальный сборник сметных цен на материалы, изделия и конструкции, применяемые в строительстве. Санкт-Петербург. ТССЦ-2001»"
              type="info"
              showIcon
            />
          </div>

        </div>
      </div>  
    </React.Fragment>
  )
}

